import React from "react";

const MathematicaLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} >
        <g fillRule="evenodd">
            <path fill="#eb989e" d="m24.486 4.083-4.433 6.943 3.98 5.626 4.434-5.538z" />
            <path fill="#e93837" d="m28.467 11.114-4.434 5.538 7.601-3.077 4.886-5.538z" />
            <path fill="#f58661" d="m31.634 13.575-.092 8.263 4.254-5.537.723-8.264-4.885 5.538" />
            <path fill="#f7a498" d="m35.797 16.3-4.253 5.538 6.605 2.373 5.609-6.417z" />
            <path fill="#f3736c" d="m38.149 24.211-6.607-2.373 4.797 6.066 7.6 2.988z" />
            <path fill="#7a1b1c" d="m36.339 27.903-7.601 2.022 6.967 1.934 8.235-.967z" />
            <path fill="#d12b33" d="m35.705 31.859-6.967-1.934-.452 6.416 7.6 3.956z" />
            <path fill="#f15759" d="m24.124 36.43-.09 7.646 4.252-7.735.452-6.416-4.614 6.504" />
            <path fill="#f4806d" d="m20.051 11.026-7.96-3.252 4.614 5.977 7.328 2.9z" />
            <path fill="#4c1317" d="m16.705 13.75-4.616-5.976.905 8.614 3.982 4.922z" />
            <path fill="#dc505e" d="m12.994 16.388-8.053 1.406 5.881 5.89 6.154-2.374z" />
            <path fill="#eb2537" d="m10.822 23.683-6.876 6.33 7.962-2.462 5.068-6.24z" />
            <path fill="#5f1726" d="m11.908 27.551-7.962 2.461 9.14 1.406L19.69 30.1z" />
            <path fill="#841921" d="m19.69 30.1-6.606 1.32-1.447 8.965 8.143-4.131-.09-6.154" />
            <path fill="#511119" d="m19.69 30.1.09 6.154 4.253 7.822.09-7.647L19.69 30.1" />
            <path fill="#b02a3c" d="m24.124 24.123 7.418-2.285.092-8.263-7.6 3.077.09 7.471" />
            <path fill="#e85e5c" d="m31.542 21.838-7.418 2.285 4.614 5.802 7.6-2.022z" />
            <path fill="#f7a0a0" d="M24.124 24.123 19.69 30.1l4.434 6.33 4.614-6.505z" />
            <path fill="#f58f8a" d="m11.908 27.551 7.782 2.55 4.434-5.978-7.148-2.813z" />
            <path fill="#e53147" d="m24.124 24.123-.09-7.471-7.33-2.901.272 7.56 7.148 2.812" />
        </g>
    </svg>
);
export default MathematicaLogo;
