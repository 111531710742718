import { motion } from "framer-motion";
import { forwardRef, useState } from "react";
import ContactIllustration from "../components/ContactIllustration";
import { firebase_db } from "../firebase/Firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const LetsTalk = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    projectDescription: "",
    read: false
  });
  const [showDialog, setShowDialog] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Save to firestore...
    addDoc(collection(firebase_db, 'messages'), {
      ...formData,
      created_at: serverTimestamp()
    }).then(() => {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        projectDescription: "",
        read: false,
      });
      setShowDialog(true);
    }).catch(() => {
      window.alert('Error submitting the form, please try again.');
    });
  };

  return (
    <div
      ref={ref}
      className="w-full h-full bg-[#181818] overflow-hidden">
      <motion.div
        initial={{
          y: 300,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
        viewport={{
          once: true,
        }}
        className="h-full flex flex-col justify-between"
      >
        <div className="text-[#fefefe] p-5 flex w-full flex-col my-auto md:flex-row md:items-center md:justify-around">
          <form
            onSubmit={handleSubmit}
            className="border md:p-7 mt-14 p-5 rounded-lg border-[#333333] md:py-16 py-11"
          >
            <div>
              <h2 className="text-2xl mb-6 font-Playwrite font-extralight">Lets Talk</h2>
            </div>
            <div className="mb-4 flex space-x-4">
              <div className="flex-1">
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  className="w-full p-2 border border-gray-200 rounded bg-transparent placeholder:text-on_primary_light focus:outline-none focus:ring-2 focus:ring-gold"
                />
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  className="w-full p-2 border border-gray-200  rounded bg-transparent placeholder:text-on_primary_light focus:outline-none focus:ring-2 focus:ring-gold"
                />
              </div>
            </div>
            <div className="mb-4">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Email"
                className="w-full p-2 border border-gray-200  rounded bg-transparent placeholder:text-on_primary_light focus:outline-none focus:ring-2 focus:ring-gold"
              />
            </div>
            <div className="mb-4">
              <textarea
                id="projectDescription"
                name="projectDescription"
                value={formData.projectDescription}
                onChange={handleChange}
                required
                placeholder="Tell me about your project"
                className="w-full p-2 border border-gray-200 rounded bg-transparent placeholder:text-on_primary_light focus:outline-none focus:ring-2 focus:ring-gold h-32"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#fefefe] text-gray-800 font-bold py-2 rounded hover:bg-opacity-80 transition"
            >
              Submit
            </button>
          </form>
          <motion.div
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 1,
              delay: 0.3,
            }}
            viewport={{ once: true }}
            className="h-[70vh] hidden md:block"
          >
            <ContactIllustration />
          </motion.div>
        </div>


      </motion.div>

      {showDialog &&
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80" onClick={() => {
          setShowDialog(false);
        }}>
          <div className="bg-[#191919] rounded-lg p-6 shadow-lg max-w-sm w-full">
            <h2 className="text-2xl font-semibold text-white">Thanks for contacting!</h2>
            <p className="mt-2 text-[#e0e0e0]">
              We appreciate your message and will get back to you shortly.
            </p>
            <button
              onClick={() => {
                setShowDialog(false)
              }}
              className="mt-4 px-4 py-2 bg-[#202020] text-white rounded hover:bg-[#181818]"
            >
              Close
            </button>
          </div>
        </div>
      }

    </div>
  );
});

export default LetsTalk;