import React from "react";

const JupyterLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" >
        <path
            fill="#767677"
            d="M109.766 7.281a7.691 7.691 0 0 1-1.09 4.282 7.583 7.583 0 0 1-3.262 2.949 7.49 7.49 0 0 1-4.34.62 7.525 7.525 0 0 1-3.953-1.913A7.642 7.642 0 0 1 95.137 5a7.606 7.606 0 0 1 2.629-3.531 7.509 7.509 0 0 1 4.136-1.461 7.51 7.51 0 0 1 5.422 1.996 7.627 7.627 0 0 1 2.438 5.273zm0 0"
        />
        <path
            fill="#f37726"
            d="M65.758 96.79c-20.098 0-37.649-7.364-46.766-18.267a49.95 49.95 0 0 0 18.102 24.254 49.251 49.251 0 0 0 28.676 9.215 49.279 49.279 0 0 0 28.675-9.215 49.917 49.917 0 0 0 18.094-24.254C103.406 89.426 85.855 96.79 65.758 96.79zm-.008-70.907c20.098 0 37.652 7.367 46.766 18.265a49.95 49.95 0 0 0-18.102-24.253 49.27 49.27 0 0 0-28.672-9.22 49.27 49.27 0 0 0-28.672 9.22 49.909 49.909 0 0 0-18.1 24.253c9.132-10.878 26.682-18.265 46.78-18.265zm0 0"
        />
        <path
            fill="#989798"
            d="M38.164 117.984a9.671 9.671 0 0 1-1.371 5.399 9.5 9.5 0 0 1-9.59 4.504 9.405 9.405 0 0 1-4.98-2.418 9.671 9.671 0 0 1-2.809-4.797 9.73 9.73 0 0 1 .313-5.567 9.624 9.624 0 0 1 3.328-4.453 9.466 9.466 0 0 1 12.043.688 9.63 9.63 0 0 1 3.066 6.648zm0 0"
        />
        <path
            fill="#6f7070"
            d="M21.285 23.418a5.53 5.53 0 0 1-3.14-.816 5.627 5.627 0 0 1-2.618-5.672 5.612 5.612 0 0 1 1.407-2.95 5.593 5.593 0 0 1 2.789-1.664 5.46 5.46 0 0 1 3.238.184 5.539 5.539 0 0 1 2.586 1.969 5.66 5.66 0 0 1-.399 7.129 5.557 5.557 0 0 1-3.867 1.82zm0 0"
        />
    </svg>
);
export default JupyterLogo;
