import { Html, OrbitControls, useAnimations, useGLTF, useProgress } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef } from "react";

function Model(props) {
  const group = useRef();
  const { scene, animations } = useGLTF("/models/robot_playground/scene.gltf");
  const { actions } = useAnimations(animations, group);

  // Play a specific animation on load
  useEffect(() => {
    if (actions && actions[Object.keys(actions)[0]]) {
      actions[Object.keys(actions)[0]].play();
    }
  }, [actions]);

  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={scene} />
    </group>
  );
}

function Loader() {
  const { progress } = useProgress()
  return <Html
    className="text-[#606060] text-6xl"
    center>{progress}%</Html>
}

const ModelLoader = () => {
  return (
    <div className="w-full h-[35vh] mt-0 flex bg-none items-center justify-center md:justify-end md:h-[75vh] md:mt-28">
      {/* <Lottie className="w-full" animationData={RobotHi} /> */}
      <Canvas>
        {/* <ambientLight intensity={0.5} /> */}
        {/* <pointLight position={[10, 10, 10]} /> */}
        <Suspense fallback={<Loader />}>
          <Model
            position={[0, 0, 0]}
            rotation={[0.6, 0, 0]}
            scale={[1.5, 1.5, 1.5]}
          />
        </Suspense>
        <OrbitControls enablePan={false} enableZoom={false} />
      </Canvas>
    </div>
  );
};

export default ModelLoader;
