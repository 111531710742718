import { useContext, useEffect, useRef, useState } from "react";
import { inView } from "framer-motion";
import { SimEngContext, SimEngProvider } from "../State/SimEngContext";

const Pendulum = ({ running }) => {
  const canvasRef = useRef(null);
  const length = 200;
  const gravity = 0.4;
  let angle = useRef(Math.PI / 4);
  let angleVelocity = useRef(0);
  let angleAcceleration = useRef(0);
  const animationFrameId = useRef(null);
  const { setData } = useContext(SimEngContext);

  const draw = (context) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    // Calculate the pendulum position
    const x = length * Math.sin(angle.current);
    const y = length * Math.cos(angle.current);

    // Calculate height
    const h = length - y;

    // Calculate potential energy
    const PE = gravity * h;

    // Calculate angular velocity (in radians per second)
    const angularVelocity = angleVelocity.current;
    const v = length * angularVelocity;
    const KE = 0.5 * v * v;

    const TE = KE + PE;

    setData((prev) => {
      return prev.length >= 100
        ? [...prev.slice(2), { ke: KE, pe: PE, te: TE }]
        : [...prev, { ke: KE, pe: PE, te: TE }];
    });

    context.fillStyle = "#8884d8";
    context.strokeStyle = "white";

    // Draw the pendulum
    context.beginPath();
    context.moveTo(context.canvas.width / 2, 10);
    context.lineTo(context.canvas.width / 2 + x, y);
    context.stroke();

    // Draw the bob
    context.beginPath();
    context.arc(context.canvas.width / 2, 10, 5, 0, Math.PI * 2);
    context.fill();
    context.beginPath();
    context.arc(context.canvas.width / 2 + x, y, 20, 0, Math.PI * 2);
    context.fill();

    angleAcceleration.current =
      ((-1 * gravity) / length) * Math.sin(angle.current);
    angleVelocity.current += angleAcceleration.current;
    angle.current += angleVelocity.current;

    if (running) {
      animationFrameId.current = requestAnimationFrame(() => draw(context));
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (running) {
      draw(context);
    } else {
      // Cancel the animation frame when not running
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    }

    // Cleanup on unmount or when running changes
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [running]);

  return <canvas ref={canvasRef} height="230px" className="w-full" />;
};

function SimEngDevAnim() {
  const [running, setRunning] = useState(false);

  const pendulumRef = useRef();

  useEffect(() => {
    inView(pendulumRef.current, ({ target }) => {
      setRunning(true);
      return () => {
        setRunning(false);
      };
    });
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex rounded-lg flex-col p-5 items-center justify-evenly font-thin w-full h-full">
        <div className="flex flex-col gap-5 w-full">
          <div
            ref={pendulumRef}
            className="w-full flex items-center justify-center"
          >
            <Pendulum running={running} />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h1 className="text-[#fefefe] font-semibold text-3xl">Simulations</h1>
        <p className="text-center p-3 text-[#939393] font-normal">
          As a physics simulations engineer, create immersive experiences by modeling realistic physical phenomena. Focus on dynamic interactions, visual fidelity, and detailed environments. Integrate sound design and interactivity to engage users.
        </p>
      </div>
    </div>
  );
}

export default function SimEngWithProvider() {
  return (
    <SimEngProvider>
      <SimEngDevAnim />
    </SimEngProvider>
  );
}
