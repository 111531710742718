import { motion } from "framer-motion";
import ComputerModel from "../components/ComputerModel";
import { forwardRef, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firebase_db } from "../firebase/Firebase";

const LandingPage = forwardRef((props, ref) => {
  const [landingData, setLandingData] = useState(null);

  async function fetchLandingInfo() {
    const docRef = doc(firebase_db, "landing", "landing_info");
    const snapshot = await getDoc(docRef);
    const landingData = snapshot.data();
    setLandingData(landingData);
  }

  useEffect(() => {
    fetchLandingInfo();
  }, []);

  return (
    <div ref={ref} className="h-full w-full overflow-hidden shadow-md">
      <div className="relative h-full w-full my-grad">
        {/* <div
          className="w-96 h-96 bg-slate-800 rounded-full absolute z-0"
          style={{
            left: mousePos.x,
            top: mousePos.y,
          }}
        /> */}
        <div className="absolute z-0 bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]" />

        {/* <div className="absolute z-0 bottom-0 left-0 right-0 top-0 bg-[#000000] bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px]" /> */}

        {/* <div className="absolute z-0 bottom-0 left-0 right-0 top-0 h-[500px] w-[500px] rounded-full bg-[radial-gradient(circle_farthest-side,rgba(255,0,182,.15),rgba(255,255,255,0))]"></div>
        <div className="absolute bottom-0 right-[-20%] top-[-10%] h-[500px] w-[500px] rounded-full bg-[radial-gradient(circle_farthest-side,rgba(255,0,182,.15),rgba(255,255,255,0))]" /> */}

        {/* <div className="absolute z-0 bottom-0 left-0 right-0 top-0 h-full w-full items-center px-5 py-24 [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)] opacity-95" /> */}

        <div className="absolute w-full h-full z-10 top-0 left-0 flex flex-col">
          <div className=" flex flex-col-reverse px-16 my-auto items-center justify-center md:flex-row">
            <div className="flex flex-col w-full items-center justify-center md:items-start">
              {landingData ? (
                <motion.p
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 2,
                  }}
                  className="text-xl text-[#999696] md:text-2xl px-1"
                >
                  {landingData.landing_greet_text}
                </motion.p>
              ) : (
                <div className="w-2/3 h-4 bg-[#202020] rounded-xl my-1.5" />
              )}

              {landingData ? (
                <motion.h1
                  initial={{
                    // y: 100,
                    opacity: 0,
                  }}
                  animate={{
                    // y: 0,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 2,
                    delay: 0.2,
                  }}
                  className="font-bold text-5xl text-[#fefefe] md:text-8xl"
                >
                  Ruhail Mir
                </motion.h1>
              ) : (
                <div className="w-2/3 h-9 bg-[#202020] rounded-xl my-1.5" />
              )}

              {landingData ? (
                <motion.p
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    delay: 0.4,
                    duration: 3,
                  }}
                  className="text-[#999696] md:w-[90%] md:text-left py-5 md:text-xl text-sm text-center w-full"
                >
                  {landingData.landing_intro_text}
                </motion.p>
              ) : (
                <div className="w-full flex flex-col items-center md:items-start my-3">
                  <div className="w-[80%] h-4 bg-[#202020] rounded-xl my-2" />
                  <div className="w-[80%] h-4 bg-[#202020] rounded-xl my-2" />
                  <div className="w-2/3 h-4 bg-[#202020] rounded-xl my-2" />
                </div>
              )}

              {/* Scroll to learn more mobile */}
              <motion.div
                initial={{
                  y: 100,
                  opacity: 0,
                }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  delay: 0.6,
                }}
                className="py-7 flex flex-col items-center gap-1 cursor-pointer select-none md:hidden"
              >
                <p className="text-[#939393] text-center md:text-start">
                  scroll to learn more
                </p>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#939393"
                  stroke="#939393"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
                  />
                </svg>
              </motion.div>
            </div>
            <ComputerModel />
          </div>

          <div
            className="w-full hidden md:flex cursor-pointer select-none relative bottom-20 items-center justify-center"
            onClickCapture={() => {}}
          >
            {/* Scroll to learn more desktop */}
            {landingData && (
              <motion.div
                initial={{
                  y: 100,
                  opacity: 0,
                }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  delay: 1,
                }}
                className="py-7 flex flex-col items-center text-2xl"
              >
                <p className="text-[#939393] text-center">
                  scroll to learn more
                </p>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#304049"
                  stroke="#304049"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
                  />
                </svg>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default LandingPage;
