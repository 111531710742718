import { createContext, useState } from "react";

export const SimEngContext = createContext(null);

export const SimEngProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [mlData, setMLData] = useState([]);

  return (
    <SimEngContext.Provider value={{ data, setData, mlData, setMLData }}>
      {children}
    </SimEngContext.Provider>
  );
};
