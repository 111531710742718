import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
    return (
        <footer className="bg-[#202020] text-white w-full py-2">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="my-4 md:mb-0">
                        <h2 className="text-lg font-semibold">Contact Me</h2>
                        <p className="text-[#919191]">Email: ruhailmir77@gmail.com</p>
                        <p className="text-[#919191]">Phone: (+91) 9622505458</p>
                    </div>
                    <div className="flex items-center justify-center text-2xl my-5 md:flex-col">
                        <div className="flex space-x-11">
                            <a href="https://instagram.com/ruhailmir" target="_blank" rel="noopener noreferrer"
                                className="text-gray-400 hover:text-blue-500 transition">
                                <FaInstagram />
                            </a>
                            <a href="https://wa.me/+919622506468?text=Hello Ruhail," target="_blank" rel="noopener noreferrer"
                                className="text-gray-400 hover:text-blue-500 transition">
                                <FaWhatsapp />
                            </a>
                            <a href="https://twitter.com/ImRuhailMir" target="_blank" rel="noopener noreferrer"
                                className="text-gray-400 hover:text-blue-500 transition">
                                <FaXTwitter />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mt-6 text-center text-gray-500">
                    &copy; {new Date().getFullYear()} Ruhail Mir. All rights reserved.
                </div>
            </div>
        </footer>
    )
}