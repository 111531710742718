import ReactLogo from "../icons/ReactLogo";
import NextLogo from "../icons/NextLogo";
import BE_ExpressLogo from "../icons/BE_ExpressLogo";
import BE_MongoLogo from "../icons/BE_MongoLogo";
import DS_NumpyLogo from "../icons/DS_NumpyLogo";
import DS_PandasLogo from "../icons/DS_PandasLogo";
import DS_JupyterLogo from "../icons/DS_JupyterLogo";
import DES_FigmaLogo from "../icons/DES_FigmaLogo";
import DES_PSLogo from "../icons/DES_PSLogo";
import DES_XDLogo from "../icons/DES_XDLogo";
import BE_FlaskLogo from "../icons/BE_FlaskLogo";
import BE_DjangoLogo from "../icons/BE_DjangoLogo";
import BE_SpringLogo from "../icons/BE_SpringLogo";
import BE_SqlLogo from "../icons/BE_SqlLogo";
import FlutterLogo from "../icons/FlutterLogo";
import JavaLogo from "../icons/JavaLogo";
import KotlinLogo from "../icons/KotlinLogo";
import ElectronLogo from "../icons/ElectronLogo";
import { motion } from "framer-motion";
import { forwardRef, useState } from "react";
import SIM_MathematicaLogo from "../icons/SIM_MathematicaLogo";
import DS_MatplotlibLogo from "../icons/DS_MatplotlibLogo";
import SIM_MatlabLogo from "../icons/SIM_MatlabLogo";
import SIM_JuliaLogo from "../icons/SIM_JuliaLogo";
import CC_ProcessingLogo from "../icons/CC_ProcessingLogo";
import CC_P5Logo from "../icons/CC_P5Logo";
import EMB_ResberryLogo from "../icons/EMB_ResberryLogo";
import EMB_ArduinoLogo from "../icons/EMB_ArduinoLogo";
import DES_AILogo from "../icons/DES_AILogo";

const skillsData = [
  {
    category: "Frontend Skills",
    background: "bg-slate-700",
    skills: [
      { name: "React JS", logo: <ReactLogo /> },
      { name: "Next.js", logo: <NextLogo /> },
      { name: "Electron", logo: <ElectronLogo /> },
      { name: "Flutter", logo: <FlutterLogo /> },
      { name: "Kotlin", logo: <KotlinLogo /> },
      { name: "Java", logo: <JavaLogo /> },
    ],
  },
  {
    category: "Backend Skills",
    background: "bg-slate-600",
    skills: [
      { name: "Express", logo: <BE_ExpressLogo /> },
      { name: "Flask", logo: <BE_FlaskLogo /> },
      { name: "Django", logo: <BE_DjangoLogo /> },
      { name: "MongoDB", logo: <BE_MongoLogo /> },
      { name: "SQL", logo: <BE_SqlLogo /> },
      { name: "Spring Boot", logo: <BE_SpringLogo /> },
    ],
  },
  {
    category: "Data Science Skills",
    background: "bg-slate-500",
    skills: [
      { name: "Jupyter", logo: <DS_JupyterLogo /> },
      { name: "Pandas", logo: <DS_PandasLogo /> },
      { name: "Matplotlib", logo: <DS_MatplotlibLogo /> },
      { name: "NumPy", logo: <DS_NumpyLogo /> },
    ],
  },
  {
    category: "Simulation & Creative Coding",
    background: "bg-slate-400",
    skills: [
      { name: "Mathematica", logo: <SIM_MathematicaLogo /> },
      { name: "Matlab", logo: <SIM_MatlabLogo /> },
      { name: "Julia", logo: <SIM_JuliaLogo /> },
      { name: "Processing", logo: <CC_ProcessingLogo /> },
      { name: "P5", logo: <CC_P5Logo /> },
    ],
  },
  {
    category: "Embedded Skills",
    background: "bg-slate-400",
    skills: [
      { name: "Resberry", logo: <EMB_ResberryLogo /> },
      { name: "Arduino", logo: <EMB_ArduinoLogo /> },
    ],
  },
  {
    category: "Design Skills",
    background: "bg-slate-400",
    skills: [
      { name: "Photoshop", logo: <DES_PSLogo /> },
      { name: "Illustrator", logo: <DES_AILogo /> },
      { name: "XD", logo: <DES_XDLogo /> },
      { name: "Figma", logo: <DES_FigmaLogo /> },
    ],
  },
];

const Skills = forwardRef((props, ref) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleScroll = (e) => {
    const totalWidth = e.target.scrollWidth;
    const currentPos = e.target.scrollLeft;
    const index = Math.floor((currentPos / totalWidth) * skillsData.length);
    if (slideIndex !== index) setSlideIndex(index);
  };

  return (
    <div
      ref={ref}
      className="w-full relative bg-[#181818] text-white overflow-hidden"
    >
      <motion.div
        initial={{
          y: 300,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
        viewport={{
          once: true,
        }}
        className="w-full p-5 mt-14"
      >
        <div className="w-full ">
          <div
            onScroll={handleScroll}
            className="w-full 
            hide-scrollbar overflow-x-auto overflow-hidden snap-x snap-mandatory flex gap-5 md:grid md:snap-none
            md:grid-cols-3"
          >
            {skillsData.map((skill, index) => (
              <div
                key={index}
                className="
                border border-[#202020]
                w-full backdrop-blur flex flex-col items-center p-5 rounded-lg min-w-full snap-start md:w-auto md:snap-none md:min-w-0"
              >
                <h1 className="text-2xl font-thin pb-5">{skill.category}</h1>
                <div className="flex flex-wrap justify-center gap-5">
                  {skill.skills.map((skillDetails, index1) => (
                    <motion.div
                      initial={{
                        scale: 0.1,
                        opacity: 0,
                      }}
                      whileInView={{
                        scale: 1,
                        opacity: 1,
                      }}
                      viewport={{ once: true }}
                      transition={{
                        delay: 0.2 * index1,
                        duration: 0.5,
                      }}
                      key={index1}
                      className="w-24 h-20 p-2 rounded-lg flex flex-col items-center justify-center gap-2"
                    >
                      {skillDetails.logo}
                      <span className="text-sm font-extralight text-slate-400">
                        {skillDetails.name}
                      </span>
                    </motion.div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="w-full h-[50px] flex items-center justify-center gap-3 md:hidden">
            {skillsData.map((_, index) => (
              <motion.div
                key={index}
                initial={{
                  width: "16px",
                  height: "16px",
                }}
                animate={{
                  width: index === slideIndex ? "30px" : "16px",
                  height: "16px",
                }}
                className={`w-5 h-5 rounded-full ${
                  slideIndex === index ? "bg-[#fefefe]" : "bg-[#202020]"
                }`}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
});

export default Skills;
