import { motion } from "framer-motion";
import { forwardRef, useEffect, useRef, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { firebase_db } from "../firebase/Firebase";

const MyProjects = forwardRef((props, ref) => {
  const [projectData, setProjectData] = useState([]);
  const viewportWidth = useRef(window.innerWidth);

  useEffect(() => {
    const usersRef = collection(firebase_db, 'projects');
    const sortedQuery = query(usersRef, orderBy('created_at'));

    getDocs(sortedQuery).then((querySnapshot) => {
      const dataList = querySnapshot
        .docs
        .map((it) => it.data());
      setProjectData(dataList);
    })

  }, []);

  return (
    <div
      ref={ref}
      className="w-full bg-[#181818] text-white overflow-hidden">
      <motion.div
        initial={{
          y: 300,
          opacity: 0,
        }}
        whileInView={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1,
        }}
        viewport={{
          once: true,
        }}
        className="w-full p-5 mt-14"
      >
        <div className="w-full flex flex-col gap-5 md:grid grid-cols-2">
          {projectData.map((project, index) => (
            <motion.div
              initial={{
                x: viewportWidth.current < 732 ? ((index % 2 === 0) ? 200 : -200) : 0,
                opacity: 0.5
              }}
              whileInView={{
                x: 0,
                opacity: 1
              }}
              viewport={{ once: true }}
              transition={{
                duration: 0.7,
              }}
              onClick={() => {
                if (project.link && project.link.length > 0)
                  window.open(project.link, '_blank')
              }}
              key={index} className="bg-[#202020] rounded-lg">
              <div>
                <img
                  className="rounded-t-lg"
                  src={project.featured_image} alt={`Featured ${index + 1}`} />
              </div>
              <div className="p-2">
                <h1 className="text-2xl font-light">{project.title}</h1>
                <p className="font-light text-[#939393]">{project.description}</p>
                <div className="flex gap-3 flex-wrap my-5">
                  {project.tags.map((tag, idx) => (
                    <div
                      key={idx}
                      className="px-4 py-1.5 bg-[#191919] text-[#939393] rounded-full">
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );

})

export default MyProjects;