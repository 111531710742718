import {
  Bar,
  BarChart,
  Legend,
  Tooltip,
  XAxis,
} from "recharts";

function TopBigPlot() {
  const data = [
    {
      name: "Fullstack",
      exp: 6,
      proj: 15,
    },
    {
      name: "ML",
      exp: 5,
      proj: 9,
    },
    {
      name: "Simulation",
      exp: 7,
      proj: 14,
    },
  ];

  return (
    <BarChart className="w-full" width={275} height={273} data={data}>
      <XAxis dataKey="name" stroke="#9C9FB0" />
      <Tooltip />
      <Legend />
      <Bar dataKey="exp" fill="#8884d8" />
      <Bar dataKey="proj" fill="#82ca9d" />
    </BarChart>
  );
}

export default function DataScienceAnim() {
  return (
    <div className="flex flex-col gap-5">
      <div
        className="flex flex-col p-5 items-center justify-evenly w-full font-thin h-full"
      >
        <div className="flex flex-col gap-5 w-full ">
          <div className="w-full flex flex-col gap-2 items-center justify-center ">
            <TopBigPlot />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <h1 className="text-[#fefefe] font-semibold text-3xl">Data Science</h1>
        <p className="text-center p-3 text-[#939393] font-normal">
          As a data scientist, use data visualization and statistical analysis to uncover trends and insights. Leverage Jupyter notebooks for interactive data exploration and storytelling, transforming complex datasets into compelling visuals that inform decision-making.
        </p>
      </div>
    </div>
  );
}
