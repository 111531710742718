import LandingPage from "./pages/Landing";
import Skills from "./pages/Skills";
import Interests from "./pages/Interests";
import LetsTalk from "./pages/LetsTalk";
import MYQuotes from "./pages/MyQuotes";
import MyProjects from "./pages/MyProjects";
import AnimatedCursor from "react-animated-cursor";
import { useEffect, useRef, useState } from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { firebase_db } from "./firebase/Firebase";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

export default function App() {
  const landingRef = useRef();
  const interestsRef = useRef();
  const skillsRef = useRef();
  const worksRef = useRef();
  const contactRef = useRef();
  const viewportWidth = useRef(window.innerWidth);
  const [active, setActive] = useState(0);
  const [menu, setMenu] = useState(false);

  const goto = (sectionName) => {
    if (sectionName === "about")
      landingRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    else if (sectionName === "interests")
      interestsRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    else if (sectionName === "skills")
      skillsRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    else if (sectionName === "projects")
      worksRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    else if (sectionName === "contact")
      contactRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
  };

  function isElementVisible(element) {
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    // Check if the element is within the viewport
    const isVisible =
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.top + 100 < windowHeight &&
      rect.left < windowWidth;

    return isVisible;
  }

  const setProperActive = () => {
    [landingRef, interestsRef, skillsRef, worksRef, contactRef].forEach(
      (it, index) => {
        if (isElementVisible(it.current)) setActive(index);
      }
    );
  };

  async function updateVisits() {
    const hasVisited = window.localStorage.getItem("VS");

    if (!hasVisited) {
      const today = new Date();
      const shortDayName = today
        .toLocaleString("en-US", { weekday: "short" })
        .toUpperCase();

      const docRef = doc(firebase_db, "visits", "weekly");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const currentData = docSnap.data();
        const currentTodayValue = currentData[shortDayName];

        await updateDoc(docRef, {
          total: currentData["total"] + 1,
          [shortDayName]: currentTodayValue + 1,
          created_at: serverTimestamp(),
        });

        window.localStorage.setItem("VS", "true");
      }
    }
  }

  useEffect(() => {
    if (viewportWidth.current > 768) {
      window.addEventListener("scrollend", setProperActive);
    }

    updateVisits();
  }, []);

  return (
    <div>
      <div className="h-screen">
        <LandingPage ref={landingRef} />
        <Nav
          active={active}
          setActive={setActive}
          menu={menu}
          setMenu={setMenu}
          gotoPage={goto}
        />

        {/* Interests */}
        <Interests ref={interestsRef} />

        {/* Skills */}
        <Skills ref={skillsRef} />

        {/* My Projects */}
        <MyProjects ref={worksRef} />

        {/* Quotes */}
        <MYQuotes />

        {/* Contact */}
        <LetsTalk ref={contactRef} />

        {/* Footer */}
        <Footer />

        <AnimatedCursor
          innerSize={8}
          outerSize={40}
          color="255, 74, 23"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={1}
          outerStyle={{
            border: "2px solid #ff4a17",
            background: "none",
          }}
        />
      </div>
    </div>
  );
}
