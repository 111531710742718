import { SimEngContext, SimEngProvider } from "../State/SimEngContext";
import { useContext, useEffect, useRef, useState } from "react";
import { inView } from "framer-motion";

const PolyRegression = ({ running }) => {
  const { setMLData } = useContext(SimEngContext);
  const canvasRef = useRef(null);
  const animationFrameId = useRef(null);
  const coef = {
    a: -0.4,
    b: 0.3,
    c: -0.3,
    d: 0.0,
  };
  const lr = 0.003;
  const points = Array.from({ length: 100 }, (_, i) => {
    const x = (i / 99) * 2 - 1;
    const y = x ** 3 + (Math.random() * 0.3 - 0.05);
    return { x: parseFloat(x.toFixed(2)), y: parseFloat(y.toFixed(2)) };
  });
  let globalError = 0;

  const objectiveFunction = (x) => {
    return (
      coef.a * Math.pow(x, 3) + coef.b * Math.pow(x, 2) + coef.c * x + coef.d
    );
  };

  const gradientDescent = () => {
    points.forEach((pt) => {
      const predictedValue = objectiveFunction(pt.x);
      const trueValue = pt.y;
      const error = trueValue - predictedValue;
      globalError += error ** 2;

      coef.a += Math.pow(pt.x, 3) * error * lr;
      coef.b += Math.pow(pt.x, 2) * error * lr;
      coef.c += pt.x * error * lr;
      coef.d += error * lr;
    });

    globalError /= points.length;
    const accuracy = 0.5 - globalError;

    setMLData((prev) => [...prev, { err: globalError, acc: accuracy }]);
  };

  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Draw points
    ctx.fillStyle = "#8884d8";
    points.forEach((pt) => {
      const xscaled = ((pt.x + 1) / 2) * ctx.canvas.width;
      const yscaled = ((pt.y + 1) / 2) * ctx.canvas.height;
      ctx.beginPath();
      ctx.arc(xscaled, yscaled, 2, 0, Math.PI * 2);
      ctx.fill();
    });

    // Draw curve
    ctx.strokeStyle = "#82ca9d";
    ctx.beginPath();
    for (let x = -1; x <= 1; x += 0.1) {
      const y = objectiveFunction(x);
      const xscaled = ((x + 1) / 2) * ctx.canvas.width;
      const yscaled = ((y + 1) / 2) * ctx.canvas.height;
      ctx.lineTo(xscaled, yscaled);
    }
    ctx.stroke();

    gradientDescent();

    if (running && globalError > 0.008) {
      animationFrameId.current = requestAnimationFrame(() => draw(ctx));
    } else {
      console.log("STOPPED NOW.");
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (running) {
      draw(context);
    } else {
      // Cancel the animation frame when not running
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    }

    // Cleanup on unmount or when running changes
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, [running]);

  return <canvas ref={canvasRef} height="230px" className="w-full" />;
};

function MLAnim() {
  const [running, setRunning] = useState(false);
  const polyRegRef = useRef();

  const { setMLData } = useContext(SimEngContext);

  useEffect(() => {
    inView(polyRegRef.current, () => {
      setMLData([]);
      setRunning(true);
      return () => {
        setRunning(false);
      };
    });
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col p-5 items-center justify-evenly font-thin w-full h-full">
        <div className="flex flex-col gap-5 w-full h-full">
          <div
            ref={polyRegRef}
            className="w-full flex items-center justify-center 
                   
                      "
            // onMouseEnter={() => setRunning(true)}
            // onMouseLeave={() => setRunning(false)}
          >
            <PolyRegression running={running} />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <h1 className="text-[#fefefe] font-semibold text-3xl">
          Machine Learning
        </h1>
        <p className="text-center p-3 text-[#939393] font-normal">
          As a machine learning engineer, develop and train models using PyTorch
          and TensorFlow. Apply deep learning techniques, optimize algorithms
          for performance, and leverage your understanding of the underlying
          math to solve complex problems with innovative solutions.
        </p>
      </div>
    </div>
  );
}

export default function MLAnimWithProvider() {
  return (
    <SimEngProvider>
      <MLAnim />
    </SimEngProvider>
  );
}
