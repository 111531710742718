export default function MYQuotes() {
  const quotes = [
    "Computer programming is the lens that unlocks your potential to envision and appreciate the beauty of Mathematics.",
    "If YOU are searching for the greatest teacher YOU will ever have, YOU got the answer.",
    "Sometimes the most valuable things in life are lost by focusing too heavily on the less important.",
  ];

  return (
    <div className="w-full md:p-7 bg-[#191919]">
      <div className="md:rounded-lg">
        <div
        >
          <div className="w-full text-2xl font-Playwrite font-extralight flex flex-col items-center p-5 rounded-lg">
            <span className="text-5xl w-full font-serif text-slate-500">
              &ldquo;
            </span>
            <p
              className="text-xl text-[#939393]"
            >{quotes[Math.floor(Math.random() * quotes.length)]}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
